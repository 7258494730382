module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Moore's Sewing","short_name":"Moore's Sew","start_url":"/","background_color":"#ffffff","theme_color":"#800000","display":"minimal-ui","icon":"/opt/build/repo/src/images/moores-sew-m.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c0cf495788b8ef18fa8d7980b20c9246"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"navigateFallbackWhitelist":[{}],"workboxConfig":{"importWorkboxFrom":"local","cacheId":"gatsby-plugin-offline","dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":1}}],"skipWaiting":true,"clientsClaim":true}},
    },{
      plugin: require('../node_modules/@simplur/gatsby-theme-full-site/gatsby-browser.js'),
      options: {"plugins":[],"appId":"moores-micro","isFullSite":false,"wordpressUrl":"https://www.moores-sew.com","postcode":"92610","imageCDN":"https://mooressew.wpenginepowered.com","siteIcon":"/opt/build/repo/src/images/moores-sew-m.svg","revisionVersion":51,"brandFullName":"Moore's Sewing","brandShortName":"Moore's Sew","brandAddress":"Moore's Sewing, 25390 Marguerite Pkwy, Mission Viejo, CA, 92692, US","brandPhone":"800-865-9664","liveSiteUrl":"https://live.moores-sew.com","productionUrl":"https://mooresstaging.wpengine.com/","themeColor":"#800000","homePageId":"3701","imagePath":"/opt/build/repo/src/images","homeBackgroundImage":"moores-bg.jpg","productBaseSlug":"shop","singleProductBaseSlug":"shop","locationBaseSlug":"location","blogBaseSlug":"sewing-blog","algolia":{"appId":"72N40NXTNV","searchKey":"7857b5b8a64369ecf8a502ff62b6ddbf","searchIndices":[]},"stripe":{"publicKey":"pk_live_51H5EdZJ3QPNTG35WcJWG23XJoLZQ86CDzWbR1VwZREIIsKUucU1h69dUUcZRi0Zh3ky0K3j2L3oOU6YQcbPKrJEu00cjNMR7Ra"},"google":{"mapApiKey":"AIzaSyCi_UGl2eABWh5ZlVdRhUFm1DNa2GacYfw","GTMId":"GTM-NFT5855","trackingIds":["UA-32829364-1"]},"firebase":{"apiKey":"AIzaSyCw_99xLa9-UR3ZVtYVvyKzHj3MnKenhMs","authDomain":"live-stream-60823.firebaseapp.com","dbUrl":"https://live-stream-60823.firebaseio.com","projectId":"live-stream-60823","senderId":"1083622907384","UIkey":"ba393bc3_2122_4cc2_af5d_1c3c28092d3a","fieldName":"liveStreamFields","appId":"1:594600632850:web:d8d9c83ffb20951a3d2ecb"},"noCodeApi":{"RFPId":"OIJJqyQoVcBoclHI","attendanceId":"VbTPcMUvfGkoAAPv","replyEmail":"mooressewing@gmail.com"},"synchrony":{"merchantId":"5348120511006173","libUrl":"https://buy.syf.com/digitalbuy/js/merchant_ff.js"},"feature":{"wishlist":false,"useBrandTemplate":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
